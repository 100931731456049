import {
  TimePicker,
  TimePickerChangeEvent
} from '@progress/kendo-react-dateinputs';
import {
  Input,
  NumericTextBox,
  TextArea,
  TextAreaChangeEvent,
  NumericTextBoxChangeEvent,
  InputChangeEvent
} from '@progress/kendo-react-inputs';
import React, { useContext } from 'react';
import {
  AnswerCheckbox,
  AnswerFile,
  AnswerStatus,
  AnswerRadio
} from '../feedback-questionnaire';
import { DateTime } from 'luxon';
import { getHourMinuteFormat } from '../../utils/timer';
import { AnswerType } from '../../types/game-document/entities/task-content';
import AnswerVideo from './answer-video';
import AnswerImage from './answer-image';
import { FeedbackDetailOptions } from '../../types/feedback-document';
import { generateTitleById } from '../../utils/game-document/display-languages';
import { DisplayLanguageFeedbackContext } from '../../contexts/display-languages-feedback';
import { FeedbackDocumentContext } from '../../contexts/feedback-document';

export interface AnswerProps {
  isReadonly?: boolean;
  name?: string;
  answerType?: AnswerType;
  answerInput?: string | number;
  checkboxOption?: FeedbackDetailOptions[];
  options?: FeedbackDetailOptions[];
  answerOptionIndex?: number;
  isCorrectAnswer?: boolean;
  formId?: string;
  index: number;
  onChangeOptionIndex?: (index: number) => void;
  onCheckboxChange?: (index: number, value: boolean) => void;
  onChangeAnswerInput?: (
    answer: string | number | File,
    index: number,
    size?: number
  ) => void;
}

const AnswerFeedback = ({
  isReadonly = false,
  name,
  answerType,
  answerInput,
  checkboxOption,
  options,
  answerOptionIndex,
  isCorrectAnswer,
  index,
  formId = '',
  onChangeAnswerInput = () => {},
  onCheckboxChange = () => {},
  onChangeOptionIndex = () => {}
}: AnswerProps) => {
  const [state] = useContext(FeedbackDocumentContext);
  const [feedbackDisplayLangContext] = useContext(
    DisplayLanguageFeedbackContext
  );
  const onChangeOptionIndexHandler = (index: number) => {
    onChangeOptionIndex(index);
  };

  const onTextInputChangeHandler = (e: InputChangeEvent) => {
    onChangeAnswerInput(e.value, index, 0);
  };

  const onNumericInputChangeHandler = (e: NumericTextBoxChangeEvent) => {
    const value: number = e.value ?? 0;
    onChangeAnswerInput(value, index, 0);
  };

  const onTextAreaInputChangeHandler = (e: TextAreaChangeEvent) => {
    onChangeAnswerInput(e.value, index, 0);
  };

  const onSelectedImageChangeHandler = (
    blobUrl: string | File,
    size?: number
  ) => {
    onChangeAnswerInput(blobUrl, index, size);
  };

  const onCheckboxChangeHandler = (index: number, value: boolean) => {
    onCheckboxChange(index, value);
  };

  const onTimePickerChangeHandler = (e: TimePickerChangeEvent) => {
    const timeFormat: string = getHourMinuteFormat(e.value);
    onChangeAnswerInput(timeFormat, index, 0);
  };

  const renderAnswer = (): JSX.Element => {
    switch (answerType) {
      case 'file':
        return (
          <AnswerFile
            isReadonly={isReadonly}
            selectedImage={answerInput?.toString()}
            onSelectedImageCallback={onSelectedImageChangeHandler}
          />
        );

      case 'video':
        return (
          <AnswerVideo
            isReadonly={isReadonly}
            selectedImage={answerInput?.toString()}
            onSelectedImageCallback={onSelectedImageChangeHandler}
            formId={formId}
          />
        );
      case 'image':
        return (
          <AnswerImage
            isReadonly={isReadonly}
            selectedImage={answerInput?.toString()}
            onSelectedImageCallback={onSelectedImageChangeHandler}
            formId={formId}
          />
        );
      case 'radio':
        return (
          <AnswerRadio
            group={name}
            options={checkboxOption}
            answerOptionSelected={answerOptionIndex}
            onChangeOptionIndex={onChangeOptionIndexHandler}
            formId={formId}
          />
        );
      case 'checkbox':
        return (
          <AnswerCheckbox
            group={name}
            checkboxOptions={checkboxOption}
            onChange={onCheckboxChangeHandler}
          />
        );
      case 'text':
        return (
          <>
            <Input
              id={'answer-text'}
              value={answerInput}
              onChange={onTextInputChangeHandler}
              placeholder={
                generateTitleById(
                  '9218c923-0be1-47f8-ae56-565694b5de33',
                  state,
                  feedbackDisplayLangContext.displayLanguageSelected.resources!,
                  'feedback'
                ) || 'Type your answer here'
              }
              className={
                isCorrectAnswer === undefined
                  ? 'border-dark'
                  : isCorrectAnswer
                    ? 'border-success'
                    : 'border-danger'
              }
            />
            <AnswerStatus isCorrectAnswer={isCorrectAnswer} />
          </>
        );
      case 'number':
        return (
          <>
            <NumericTextBox
              id={'answer-number'}
              value={parseInt(answerInput?.toString() ?? '0') ?? 0}
              onChange={onNumericInputChangeHandler}
              className={
                isCorrectAnswer === undefined
                  ? 'border-dark'
                  : isCorrectAnswer
                    ? 'border-success'
                    : 'border-danger'
              }
            />
            <AnswerStatus isCorrectAnswer={isCorrectAnswer} />
          </>
        );
      case 'textarea':
        return (
          <>
            <TextArea
              id={'answer-textarea'}
              value={answerInput}
              rows={6}
              onChange={onTextAreaInputChangeHandler}
              placeholder={
                generateTitleById(
                  '9218c923-0be1-47f8-ae56-565694b5de33',
                  state,
                  feedbackDisplayLangContext.displayLanguageSelected.resources!,
                  'feedback'
                ) || 'Type your answer here'
              }
              className={
                isCorrectAnswer === undefined
                  ? 'border-dark'
                  : isCorrectAnswer
                    ? 'border-success'
                    : 'border-danger'
              }
            />
            <AnswerStatus isCorrectAnswer={isCorrectAnswer} />
          </>
        );
      case 'time':
        return (
          <>
            <TimePicker
              id={'answer-time'}
              value={
                answerInput !== undefined &&
                answerInput !== '' &&
                DateTime.fromISO(answerInput.toString()).isValid
                  ? DateTime.fromISO(answerInput.toString()).toJSDate()
                  : null
              }
              onChange={onTimePickerChangeHandler}
              className={'border-dark'}
            />
          </>
        );
      default: // 'none'
        return <></>;
    }
  };

  return (
    <div
      id={`answer-type-${answerType}`}
      className={`answer-container overflow-y-auto overflow-x-hidden ${
        answerType === 'checkbox' || answerType === 'radio'
          ? 'min-h-10'
          : 'min-h-3'
      }`}>
      {renderAnswer()}
    </div>
  );
};

export default AnswerFeedback;
