import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ChatWindow from '../../../../../components/chat/chat-window';

export const Chat = () => {
  return (
    <>
      <Container className={'drawer__body p-0'}>
        <Row>
          <Col className={'p-4'}>
            <ChatWindow />
          </Col>
        </Row>
      </Container>
      <Container className={'drawer__footer p-0 text-center pb-2'} />
    </>
  );
};
