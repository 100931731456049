import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { MapContext } from './map-context';
import GeoJSON from 'ol/format/GeoJSON';
import Geolocation from 'ol/Geolocation';
import { Vector as VectorLayer } from 'ol/layer';
import { BingMaps, OSM, Vector as VectorSource } from 'ol/source';
import { Style, Fill, Stroke } from 'ol/style';
import { Resource, WorldMapTask } from '../../types/game-document';
import { GameDocumentContext } from '../../contexts/game-document';
import { PlayerContext, PlayerContextState } from '../../contexts/player';
import {
  GetAreaById as GetAreaByIdFromDocument,
  GetAreasByZoneId,
  GetAreasWorldMap,
  GetTimerById,
  GetZoneById,
  GetZoneRulesById,
  GetZonesByIds
} from '../../utils/game-document/assets';
//Todo Move into Layout (In Game)
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState
} from '@microsoft/signalr';
import {
  playerHub,
  postPlayerState,
  teamHub
} from '../../services/syncronize-state';
import { TaskContent } from '../../features/task/task-content';
import {
  AreaEntity,
  MapEntity,
  TaskEntity,
  TimerEntity
} from '../../types/game-document/entities';
import {
  AddExecutedStepAsync,
  AddTimer,
  GiveScore,
  HideTask,
  IsStepExecuted,
  NavigateToWorldMap,
  OpenTask,
  PauseAllTimers as PlayerPauseAllTimers,
  PauseTimer,
  PlayerNavigateToZone,
  RemoveScore,
  ResumeAllTimers as PlayerResumeAllTimers,
  ResumeTimer,
  ShowHideZoneArea,
  ShowTask,
  StartTimer,
  StopAllTimers as PlayerStopAllTimers,
  StopTimer,
  ShowTaskAsync,
  AdjustScore,
  UpdatePlayerCoordinate
} from '../../utils/game-engine/player';
import {
  GiveScore as TeamGiveScore,
  RemoveScore as TeamRemoveScore,
  TeamGiveItemAsync,
  TeamGiveTitleAsync,
  TeamRemoveItemAsync,
  TeamRemoveTitleAsync
} from '../../utils/game-engine/team';
import {
  UpdatePlayerStateAsync,
  UpdateTeamStateAsync
} from '../../utils/game-engine/base';
import {
  NotificationContent,
  NotificationContext
} from '../../contexts/notification';
import { GameContext } from '../../contexts/game';
import { GameStatus, PlayerStateFull } from '../../types/state';
import { MultiPoint, SimpleGeometry } from 'ol/geom';
import CircleStyle from 'ol/style/Circle';
import { GetResourceValue } from '../../utils/game-document/resources';
import {
  AddArea,
  AddItem,
  AddTask,
  GetAreaById,
  GetTaskById,
  RemoveItem
} from '../../utils/game-engine/assets';
import ListenerManager from '../../utils/listener-manager';
import { GetItemAchievement } from '../../features/in-game/overlay/drawer/tabs/inventory';
import { AddTitle, RemoveTitle } from '../../utils/game-engine/assets/titles';
import { GetTitleAchievement } from '../../features/in-game/overlay/drawer/tabs/roles';
import { Task } from '../../types/entities/task';
import {
  AchievementContent,
  AchievementContext
} from '../../contexts/achievement';
import { MapIllustrationContext } from './map-illustration-context';
import { fromLonLat, Projection, toLonLat } from 'ol/proj';
import ImageLayer from 'ol/layer/Image';
import Static from 'ol/source/ImageStatic';
import { calculateCenter } from '../../utils/map-helper';
import { TeamContext } from '../../contexts/team';
import { Area } from '../../types/entities/area';
import { useGameClock } from '../../hooks/use-game-clock';
import { useInterval } from 'usehooks-ts';
import cloneDeep from 'lodash.clonedeep';
import { PlayerPosition } from '../../features/in-game/player-position';
import {
  AlgorithmControlStructure,
  AlgorithmStep
} from '../../types/algorithm';
import { getCenter } from 'ol/extent';
import View from 'ol/View';
import {
  AlgorithmEventHandlerAsync,
  AlgorithmEventHandlerPlayer,
  executeControlStructureStepEventHandler
} from '../../utils/game-engine/game';
import Feature from 'ol/Feature';
import { isEqual } from 'lodash';
import { MapBrowserEvent } from 'ol';
import { Color } from 'ol/color';
import { postTeamStateAsync } from '../../services/teams';
import usePrevious from '../../hooks/use-previous';
import MoveMapPad from './map-move-pad';
import { isNetworkOnline } from '../../utils/indicator';
import { pushQueueCommandLocalstorage } from '../../utils/game-engine/queue-handler';
import { Switch, SwitchChangeEvent } from '@progress/kendo-react-inputs';
import TileLayer from 'ol/layer/Tile';
import { BingMapsKey } from '../../constants/bing-key';
import { getFacilitatorContent } from '../../services/games';
import { UpdatePlayerContext } from '../../utils/player-state';
import { UpdateTeamContext } from '../../utils/team-state';
import { generateTitleById } from '../../utils/game-document/display-languages';
import { distributeTaskAsync } from '../../services/players';
import merge from 'lodash.merge';
import { useGamePlayerPosition } from '../../hooks/use-game-player-position';
import MapResetRotate from './map-reset-rotate';
import { DisplayLanguageContext } from '../../contexts/display-languages';
import { useGameTask } from '../../hooks/use-game-task';
import { getPlayerLocationStatus } from '../../utils/game-document/location';
import GameStatusOverlay from '../game-status-overlay';

export type LogoArea = '' | 'zone' | 'task';
interface MapsProps {
  task?: WorldMapTask;
  resourceImages?: Resource[];
}

export default function Map({ task, resourceImages }: MapsProps) {
  //Todo Move into Layout (In Game)
  const mapElement = useRef();
  const map = useContext(MapContext);
  const mapIllustration = useContext(MapIllustrationContext);
  const [gameDocument] = useContext(GameDocumentContext);
  const [player, setPlayer] = useContext(PlayerContext);
  const [team, setTeam] = useContext(TeamContext);
  const [game, setGame] = useContext(GameContext);
  const [notification, setNotification] = useContext(NotificationContext);
  const [achievement, setAchievement] = useContext(AchievementContext);
  const [displayLanguageContext] = useContext(DisplayLanguageContext);

  const [showLocation, setShowLocation] = useState<boolean>(true);
  const [playerHubConnection, setPlayerHubConnection] =
    useState<HubConnection>();
  const [teamHubConnection, setTeamHubConnection] = useState<HubConnection>();
  const [mapType, setMapType] = useState<string>('');
  const [isIllustrationMapLoaded, setIsIllustrationMapLoaded] =
    useState<boolean>(false);
  const [isGameDocLoaded, setIsGameDocLoaded] = useState<boolean>(false);
  const [isMapTypeLoaded, setIsMapTypeLoaded] = useState<boolean>(false);
  const [accuracy, setAccuracy] = useState<number | undefined>();
  const [isOpenStreetMap, setIsOpenStreetMap] = useState<boolean>(true);
  const [taskDistance, setTaskDistance] = useState<number>(0);

  const objectLineColor: string | Color = gameDocument?.theme?.colors?.find(
    (x) => x.type === 'Info'
  )?.color ?? [255, 85, 39];

  const {
    addAllPlayer,
    addMine,
    updatePlayerOverlay,
    updatePlayerOverlayPosition
  } = useGamePlayerPosition({ mapType });

  const prevPlayerOpenedTask =
    usePrevious(cloneDeep(player.playerState?.openedTask)) ?? [];
  const prevPlayerLocation = usePrevious(player.playerState?.location);
  const prevPlayerCoordinates = usePrevious(
    cloneDeep(player.playerState?.coordinates)
  );
  const prevGameStatus = usePrevious(game.gameState?.status) ?? '';
  const playerCode = player?.playerState?.code;
  const gameDoc = gameDocument?.gameDocument;
  const currentOpenedTask = player.playerState?.openedTask
    ? player.playerState.openedTask[player.playerState.openedTask.length - 1]
    : undefined;
  //call gameClock hooks
  const gameClock = useGameClock(
    { showLogs: false },
    {
      onStart: async (timerId: string) => {
        await onStartTimerHandler(timerId);
      },
      onStop: async (timerId: string) => {
        await onStopTimerHandler(timerId);
      },
      onTimerElapsed: async (timerId: string) => {
        await onElapsedTimerHandler(timerId);
      }
    }
  );

  const onStartTimerHandler = async (timerId: string) => {
    const timerEvents =
      GetTimerById(gameDocument.gameDocument, timerId)[0] ??
      ({} as TimerEntity);

    const { events: { onStartTimer: { steps = [] } = {} } = {} }: TimerEntity =
      timerEvents;
    await executeAlgorithmSteps(steps, false);
  };

  const onStopTimerHandler = async (timerId: string) => {
    const timerEvents =
      GetTimerById(gameDocument.gameDocument, timerId)[0] ??
      ({} as TimerEntity);

    const { events: { onStopTimer: { steps = [] } = {} } = {} }: TimerEntity =
      timerEvents;
    await executeAlgorithmSteps(steps, false);
  };

  const onElapsedTimerHandler = async (timerId: string) => {
    const timerEvents =
      GetTimerById(gameDocument.gameDocument, timerId)[0] ??
      ({} as TimerEntity);

    const {
      events: { onElapsedTimer: { steps = [] } = {} } = {}
    }: TimerEntity = timerEvents;
    await executeAlgorithmSteps(steps, false);
  };

  const teamConnection = async () => {
    try {
      if (player.playerState?.teamCode) {
        const hub = await teamHub(player.playerState?.teamCode!);
        const connectionHub = new HubConnectionBuilder()
          .withUrl(`${hub.endpointUrl}`, {
            accessTokenFactory: () => hub.accessToken!
          })
          .withAutomaticReconnect()
          .build();

        setTeamHubConnection(connectionHub);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const syncPlayerState = useCallback(async () => {
    try {
      await postPlayerState(
        game.gameCode!,
        player.playerState?.code!,
        JSON.stringify(player)
      );
    } catch (err) {
      console.error(err);
    }
  }, [game.gameCode, player]);

  const playerConnection = async () => {
    try {
      const hub = await playerHub(player.playerState?.code!);
      const connectionHub = new HubConnectionBuilder()
        .withUrl(`${hub.endpointUrl}`, {
          accessTokenFactory: () => hub.accessToken!
        })
        .withAutomaticReconnect()
        .build();
      if (connectionHub.state) {
        setPlayerHubConnection(connectionHub);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const PlayerRemoveItemHandler = async (message: any) => {
    setPlayer((prev) => {
      const response = RemoveItem(prev, {
        id: message.id,
        quantity: parseInt(message.quantity)
      });
      return UpdatePlayerStateAsync(prev, response);
    });
    RemoveItemToast(message?.id, message?.quantity);
  };

  const RemoveItemToast = (
    itemId: string,
    quantity: number,
    isTeam: boolean = false
  ) => {
    const itemCard = GetItemAchievement(
      gameDoc!,
      player?.playerState?.language?.name!,
      itemId,
      quantity
    );
    const removeItemNotification: NotificationContent = {
      icon: 'warning',
      isHide: false,
      message: (
        <span>
          <strong>
            {quantity} {itemCard.titleRes} {isTeam ? 'Team' : ''} items removed
            from your Inventory
          </strong>
        </span>
      ),
      color: 'k-button--gradient'
    };
    const content: NotificationContent[] = notification.content;
    content.push(removeItemNotification);
    setNotification({ ...notification, content });
  };

  const PlayerAddItemHandler = async (message: any) => {
    setPlayer((prev) => {
      const response = AddItem(prev, {
        id: message.id,
        quantity: parseInt(message.quantity)
      });
      return UpdatePlayerStateAsync(prev, response);
    });
    AddItemToast(message.id, message.quantity);
  };

  const TeamGiveItemHandler = (message: any) => {
    setTeam((prev) => {
      const response = TeamGiveItemAsync(
        prev,
        message.id,
        parseInt(message.quantity)
      );
      return UpdateTeamStateAsync(prev, response);
    });
    AddItemToast(message.id, message.quantity, true);
  };

  const TeamRemoveItemHandler = (message: any) => {
    setTeam((prev) => {
      const response = TeamRemoveItemAsync(
        prev,
        message.id,
        parseInt(message.quantity)
      );
      return UpdateTeamStateAsync(prev, response);
    });
    RemoveItemToast(message?.id, message?.quantity, true);
  };

  const AddItemToast = (
    itemId: string,
    quantity: number,
    isTeam: boolean = false
  ) => {
    const itemCard = GetItemAchievement(
      gameDoc!,
      player?.playerState?.language?.name!,
      itemId,
      quantity
    );
    if (!itemCard.hideInGame) {
      const addItemNotification: AchievementContent = {
        isHide: false,
        imageUrl: itemCard.imageRes,
        message: (
          <>
            <h1>{itemCard.quantity}</h1>
            <h2>{itemCard.titleRes}</h2>
            <p>{itemCard.summaryRes}</p>
          </>
        )
      };

      const content: AchievementContent[] = achievement.content;
      content.push(addItemNotification);
      setAchievement({ ...achievement, content });

      addItemAlertNotification(quantity, itemCard.titleRes, isTeam);
    }
  };

  const addItemAlertNotification = (
    quantity: number,
    titleRes: string,
    isTeam: boolean
  ) => {
    const addItemAlertNotification: NotificationContent = {
      icon: 'notifications',
      isHide: false,
      message: (
        <span>
          <strong>
            {quantity} {titleRes} {isTeam ? 'Team' : ''} items added to your
            Inventory
          </strong>
        </span>
      ),
      color: 'k-button--gradient'
    };

    setNotification((prev) => ({
      ...prev,
      content: [...prev.content, addItemAlertNotification]
    }));
  };

  const addTitleAlertNotification = (titleRes: string, isTeam: boolean) => {
    const addTitleAlertNotification: NotificationContent = {
      icon: 'notifications',
      isHide: false,
      message: (
        <span>
          <strong>
            {titleRes} {isTeam ? 'Team' : ''} titles has been added
          </strong>
        </span>
      ),
      color: 'k-button--gradient'
    };

    setNotification((prev) => ({
      ...prev,
      content: [...prev.content, addTitleAlertNotification]
    }));
  };

  const PlayerAddTitleHandler = async (message: any) => {
    setPlayer((prev) => {
      const response = AddTitle(prev, message.id);
      return UpdatePlayerStateAsync(prev, response);
    });
    AddTitleToast(message?.id);
  };

  const TeamAddTitleHandler = (message: any) => {
    setTeam((prev) => {
      const response = TeamGiveTitleAsync(prev.teamState!, message.id);
      return UpdateTeamStateAsync(prev, response);
    });
    AddTitleToast(message?.id, true);
  };

  const AddTitleToast = (titleId: string, isTeam: boolean = false) => {
    const titleCard = GetTitleAchievement(
      gameDoc!,
      player?.playerState?.language?.name!,
      titleId
    );

    if (!titleCard.hideInGame) {
      const removeItemNotification: AchievementContent = {
        isHide: false,
        imageUrl: titleCard.imageRes,
        message: (
          <>
            <h1>{isTeam ? 'Team' : ''} Role</h1>
            <h2>{titleCard.titleRes}</h2>
            <p>{titleCard.summaryRes}</p>
          </>
        )
      };
      const content: AchievementContent[] = achievement.content;
      content.push(removeItemNotification);
      setAchievement({ ...achievement, content });

      addTitleAlertNotification(titleCard.titleRes, isTeam);
    }
  };

  const PlayerRemoveTitleHandler = async (message: any) => {
    setPlayer((prev) => {
      const response = RemoveTitle(prev, message.id);
      return UpdatePlayerStateAsync(prev, response);
    });
    RemoveTitleToast(message?.id);
  };

  const TeamRemoveTitleHandler = (message: any) => {
    setTeam((prev) => {
      const response = TeamRemoveTitleAsync(prev.teamState!, message?.id);
      return UpdateTeamStateAsync(prev, response);
    });
    RemoveTitleToast(message?.id, true);
  };

  const RemoveTitleToast = (titleId: string, isTeam: boolean = false) => {
    const titleCard = GetTitleAchievement(
      gameDoc!,
      player?.playerState?.language?.name!,
      titleId
    );
    const removeTitleNotification: NotificationContent = {
      icon: 'warning',
      isHide: false,
      message: (
        <span>
          <strong>
            {isTeam ? 'Team role' : ''} {titleCard.titleRes} has been removed
          </strong>
        </span>
      ),
      color: 'k-button--gradient'
    };
    const content: NotificationContent[] = notification.content;
    content.push(removeTitleNotification);
    setNotification({ ...notification, content });
  };

  const PlayerOpenTaskHandler = async (message: any) => {
    setPlayer((prev) => {
      let response = OpenTask(prev, message.id);
      return UpdatePlayerStateAsync(prev, response);
    });
    openTaskContent(message.id);
  };

  const TeamOpenTaskHandler = async (message: any) => {
    PlayerOpenTaskHandler(message);
  };

  const TeamRandomTaskHandler = async (message: any) => {
    addTaskByListener(message);
  };

  const TeamDistributeTaskHandler = async (message: any) => {
    addTaskByListener(message);
  };

  const PlayerDistributeTaskHandler = async (message: any) => {
    addTaskByListener(message);
  };

  const PlayerRandomTaskHandler = async (message: any) => {
    addTaskByListener(message);
  };

  const PlayerKickedHandler = async () => {
    setPlayer((prev) =>
      UpdatePlayerContext(prev, {
        ...prev.playerState!,
        status: 'kicked'
      })
    );
    //stop global timer
    gameClock.stop();
  };

  const PlayerAddRemoveTeamHandler = async (message: any) => {
    const playerResponseTeamCode = message.id?.replace(
      '00000000-0000-0000-0000-000000000000',
      ''
    );
    setPlayer((prev) =>
      UpdatePlayerContext(prev, {
        ...prev.playerState!,
        teamCode: playerResponseTeamCode
      })
    );
  };

  const addTaskByListener = (message: any) => {
    message?.listIds?.forEach((taskId: string) => {
      const existingTask = GetTaskById(player, taskId);
      if (!existingTask) {
        const newTask: Task = {
          id: taskId,
          isVisible: true
        };

        setPlayer((prev) => {
          const response = AddTask(prev, newTask);
          return UpdatePlayerStateAsync(prev, response);
        });
      }
    });
  };

  const TeamLocationUpdateHandler = async (message: any) => {
    PlayerLocationUpdateHandler(message);
  };

  const PlayerLocationUpdateHandler = async (message: any) => {
    if (message?.id === 'world-map') {
      setPlayer((prev) => {
        const response = NavigateToWorldMap(prev);
        return UpdatePlayerStateAsync(prev, response);
      });
    } else {
      setPlayer((prev) => {
        const response = PlayerNavigateToZone(prev, message?.id);
        return UpdatePlayerStateAsync(prev, response);
      });
    }
    populateAreas(message?.id);
  };

  const PlayerShowTaskHandler = async (message: any) => {
    setPlayer((prev) => {
      const response = ShowTask(prev, message?.id!);
      return UpdatePlayerStateAsync(prev, response);
    });
  };

  const PlayerHideTaskHandler = async (message: any) => {
    setPlayer((prev) => {
      const response = HideTask(prev, message?.id!);
      return UpdatePlayerStateAsync(prev, response);
    });
  };

  const PlayerShowAreaHandler = (message: any) => {
    setPlayer((prev) => {
      const response = ShowHideZoneArea(prev, message?.id!, true);
      return UpdatePlayerStateAsync(prev, response);
    });
  };

  const PlayerHideAreaHandler = async (message: any) => {
    setPlayer((prev) => {
      const response = ShowHideZoneArea(prev, message?.id!, false);
      return UpdatePlayerStateAsync(prev, response);
    });
  };

  const PlayerStartTimerHandler = async (message: any) => {
    setPlayer((prev) => {
      const response = StartTimer(gameDocument, prev, message?.id!, 'start');
      return UpdatePlayerStateAsync(prev, response as PlayerStateFull);
    });
    gameClock.startTimer(message?.id);
  };

  const PlayerStopTimerHandler = async (message: any) => {
    setPlayer((prev) => {
      const response = StopTimer(gameDocument, prev, message?.id!, 'stop');
      gameClock.stopTimer(prev, message?.id!);
      return UpdatePlayerStateAsync(prev, response as PlayerStateFull);
    });
  };

  const PlayerPauseTimerHandler = async (message: any) => {
    setPlayer((prev) => {
      const response = PauseTimer(gameDocument, prev, message?.id!, 'pause');
      return UpdatePlayerStateAsync(prev, response);
    });
    gameClock.pauseTimer(message?.id);
  };

  const PlayerResumeTimerHandler = async (message: any) => {
    setPlayer((prev) => {
      const response = ResumeTimer(gameDocument, prev, message?.id!, 'resume');
      return UpdatePlayerStateAsync(prev, response);
    });
    gameClock.resumeTimer(message?.id);
  };

  const PlayerRemoveScoreHandler = async (message: any) => {
    setPlayer((prev) => {
      const response = RemoveScore(prev, parseInt(message?.quantity));
      return UpdatePlayerStateAsync(prev, response);
    });
    RemoveScoreToast(message?.quantity);
  };

  const toastEventHandler = (
    player: PlayerContextState,
    step: AlgorithmStep | AlgorithmControlStructure
  ) => {
    const { argumentAssetId = '', operation = '' } = step;
    switch (operation) {
      case 'openTask':
        openTaskContent(argumentAssetId as string);
        break;
      case 'giveScore':
        AddScoreToast(step?.argumentQuantity ?? 0, step?.identifier === 'team');
        break;
      case 'removeScore':
        RemoveScoreToast(
          step?.argumentQuantity ?? 0,
          step?.identifier === 'team'
        );
        break;
      case 'giveTitle':
        AddTitleToast(
          step?.argumentAssetId as string,
          step?.identifier === 'team'
        );
        break;
      case 'removeTitle':
        RemoveTitleToast(
          step?.argumentAssetId as string,
          step?.identifier === 'team'
        );
        break;
      case 'giveItem':
        AddItemToast(
          step.argumentAssetId as string,
          step.argumentQuantity ?? 0,
          step?.identifier === 'team'
        );
        break;
      case 'removeItem':
        RemoveItemToast(
          step?.argumentAssetId as string,
          step?.argumentQuantity ?? 0,
          step?.identifier === 'team'
        );
        break;
      case 'pauseTimer':
        gameClock.pauseTimer(step?.argumentAssetId as string);
        break;
      case 'stopTimer':
        gameClock.stopTimer(player, step?.argumentAssetId as string);
        break;
      case 'startTimer':
        gameClock.startTimer(step?.argumentAssetId as string);
        break;
      case 'resumeTimer':
        gameClock.resumeTimer(step?.argumentAssetId as string);
        break;
      default:
        break;
    }
  };

  const {
    getMapTasks,
    openTaskContent,
    removeTaskDistance,
    populateTaskOverlay,
    changeTaskIconToComplete,
    resetAllTaskOverlay,
    isTaskAccessible
  } = useGameTask({
    accuracy,
    team,
    mapType,
    toastEventHandler
  });

  const RemoveScoreToast = (quantity: number, isTeam: boolean = false) => {
    const removeScoreNotification: NotificationContent = {
      icon: 'warning',
      isHide: false,
      message: (
        <span>
          <strong>
            {quantity} {isTeam ? 'Team' : ''} points removed from your
          </strong>{' '}
          <strong>submission</strong>
        </span>
      ),
      color: 'k-button--gradient'
    };
    const content: NotificationContent[] = notification.content;
    content.push(removeScoreNotification);
    setNotification({ ...notification, content });
  };

  const PlayerAddScoreHandler = async (message: any) => {
    if (message.listIds) {
      setPlayer((prev) => {
        let playerTasks1 = prev?.playerState?.tasks;

        let selectedTaskIndex1 = playerTasks1?.findIndex(
          (x) => x.id === message.listIds[0]
        );
        let selectedFormIndex1 = playerTasks1![
          selectedTaskIndex1!
        ]?.taskContentFormAnswers?.findIndex(
          (x) => x.formId === message.listIds[1]
        );
        let prevTaskAnswerScore =
          prev.playerState?.tasks![selectedTaskIndex1!].taskContentFormAnswers![
            selectedFormIndex1!
          ].score ?? 0;

        let playerScore =
          !prev.playerState?.score || prev.playerState?.score === 0
            ? parseInt(message?.quantity)
            : prev!.playerState!.score! -
              prevTaskAnswerScore +
              parseInt(message?.quantity);

        let response = AdjustScore(prev, playerScore);

        let playerTasks = response?.tasks;
        let selectedTaskIndex = playerTasks?.findIndex(
          (x) => x.id === message.listIds[0]
        );
        let selectedFormIndex = playerTasks![
          selectedTaskIndex!
        ]?.taskContentFormAnswers?.findIndex(
          (x) => x.formId === message.listIds[1]
        );

        response!.tasks![selectedTaskIndex!].taskContentFormAnswers![
          selectedFormIndex!
        ].score = parseInt(message?.quantity);

        return UpdatePlayerStateAsync(prev, response);
      });
    } else {
      setPlayer((prev) => {
        const response = GiveScore(prev, parseInt(message?.quantity));
        return UpdatePlayerStateAsync(prev, response);
      });
    }

    AddScoreToast(message?.quantity);
  };

  const AddScoreToast = (quantity: number, isTeam: boolean = false) => {
    const addScoreNotification: NotificationContent = {
      icon: 'notifications',
      isHide: false,
      message: (
        <span>
          <strong>
            {quantity} {isTeam ? 'Team' : ''}{' '}
            {generateTitleById(
              '88a6f4c3-09f5-4a93-89de-62f1d6c13938',
              gameDocument,
              displayLanguageContext.displayLanguageSelected.resources!,
              'game'
            ) || 'points awarded for your submission!'}
          </strong>
        </span>
      ),
      color: 'k-button--gradient'
    };
    const content: NotificationContent[] = notification.content;
    content.push(addScoreNotification);
    setNotification({ ...notification, content });
  };

  const TeamAddScoreHandler = async (message: any) => {
    setTeam((prev) => {
      const response = TeamGiveScore(
        prev.teamState!,
        parseInt(message?.quantity)
      );
      return UpdateTeamStateAsync(prev, response);
    });
    AddScoreToast(message?.quantity, true);
  };

  const TeamRemoveScoreHandler = async (message: any) => {
    setTeam((prev) => {
      const response = TeamRemoveScore(
        prev.teamState!,
        parseInt(message?.quantity)
      );
      return UpdateTeamStateAsync(prev, response);
    });
    RemoveScoreToast(message?.quantity, true);
  };

  const TeamPushContentHandler = async (data: any) => {
    updatePlayerTimerStatus('Paused');
    let response = await getFacilitatorContent(game?.gameCode!, data.id);
    AddContentToast(response);
  };

  const AddContentToast = (contentString: string) => {
    const removeItemNotification: AchievementContent = {
      isHide: false,
      message: (
        <div
          className={'push-content'}
          dangerouslySetInnerHTML={{ __html: `${contentString}` }}></div>
      ),
      onCloseCallback: () => {
        setPlayer((prev) => {
          const response = PlayerResumeAllTimers(prev);
          return UpdatePlayerStateAsync(prev, response);
        });
        //resume global timer
        gameClock.resume();
      }
    };
    const content: AchievementContent[] = achievement.content;
    content.push(removeItemNotification);
    setAchievement({ ...achievement, content });
  };

  const AddContentGlobalToast = (contentString: string) => {
    const removeItemNotification: AchievementContent = {
      isHide: false,
      isPushNotification: true,
      message: (
        <div
          className={'push-content'}
          dangerouslySetInnerHTML={{ __html: `${contentString}` }}></div>
      ),
      onCloseCallback: async () => {
        setGame((prev) => ({ ...prev, contentId: '' }));
        setPlayer((prev) => {
          const response = PlayerResumeAllTimers(prev);
          return UpdatePlayerStateAsync(prev, response);
        });
        //resume global timer
        gameClock.resume();
      }
    };
    const content: AchievementContent[] = achievement.content;
    content.push(removeItemNotification);
    setAchievement({ ...achievement, content });
  };

  const playerConnectionListener = async (connection: HubConnection) => {
    connection.on(`${playerCode}`, async (message) => {
      const listener = new ListenerManager({
        PlayerAddItem: PlayerAddItemHandler,
        PlayerRemoveItem: PlayerRemoveItemHandler,
        PlayerShowTask: PlayerShowTaskHandler,
        PlayerHideTask: PlayerHideTaskHandler,
        PlayerShowArea: PlayerShowAreaHandler,
        PlayerHideArea: PlayerHideAreaHandler,
        PlayerStartTimer: PlayerStartTimerHandler,
        PlayerStopTimer: PlayerStopTimerHandler,
        PlayerPauseTimer: PlayerPauseTimerHandler,
        PlayerResumeTimer: PlayerResumeTimerHandler,
        PlayerAddScore: PlayerAddScoreHandler,
        PlayerRemoveScore: PlayerRemoveScoreHandler,
        PlayerAddTitle: PlayerAddTitleHandler,
        PlayerRemoveTitle: PlayerRemoveTitleHandler,
        PlayerLocationUpdate: PlayerLocationUpdateHandler,
        PlayerOpenTask: PlayerOpenTaskHandler,
        PlayerRandomTask: PlayerRandomTaskHandler,
        PlayerDistributeTask: PlayerDistributeTaskHandler,
        PlayerKicked: PlayerKickedHandler,
        PlayerAddToTeam: PlayerAddRemoveTeamHandler,
        PlayerRemoveFromTeam: PlayerAddRemoveTeamHandler
      });

      listener.listen(message);
    });
    connection.onclose((e) => {
      console.error('error');
    });

    connection.start().finally(() => {
      if (connection.state === HubConnectionState.Connected) {
        initPlayerLocation();
      }
    });
  };

  const teamConnectionListener = async (connection: HubConnection) => {
    connection.on(`${player.playerState?.teamCode}`, async (message) => {
      const listener = new ListenerManager({
        TeamAddItem: TeamGiveItemHandler,
        TeamRemoveItem: TeamRemoveItemHandler,
        TeamAddTitle: TeamAddTitleHandler,
        TeamRemoveTitle: TeamRemoveTitleHandler,
        TeamOpenTask: TeamOpenTaskHandler,
        TeamLocationUpdate: TeamLocationUpdateHandler,
        TeamShowTask: PlayerShowTaskHandler,
        TeamHideTask: PlayerHideTaskHandler,
        TeamRandomTask: TeamRandomTaskHandler,
        TeamDistributeTask: TeamDistributeTaskHandler,
        TeamShowArea: PlayerShowAreaHandler,
        TeamHideArea: PlayerHideAreaHandler,
        TeamStartTimer: PlayerStartTimerHandler,
        TeamStopTimer: PlayerStopTimerHandler,
        TeamPauseTimer: PlayerPauseTimerHandler,
        TeamResumeTimer: PlayerResumeTimerHandler,
        TeamAddScore: TeamAddScoreHandler,
        TeamRemoveScore: TeamRemoveScoreHandler,
        TeamStateFull: TeamStateFullHandler,
        TeamFacilitatorContent: TeamPushContentHandler,
        TeamDisabledChat: TeamDisabledChatHandler,
        TeamEnabledChat: TeamEnabledChatHandler
      });
      listener.listen(message);
    });

    connection.onclose((e) => {
      console.error('error');
    });

    connection.start().finally(() => {
      if (connection.state === HubConnectionState.Connected) {
        initPlayerLocation();
      }
    });
  };

  const TeamStateFullHandler = async (message: any) => {
    if (message && message.teamStateFull) {
      setTeam((prev) => UpdateTeamStateAsync(prev, message.teamStateFull));
    }
  };

  const TeamEnabledChatHandler = async (message: any) => {
    setTeam((prev) =>
      UpdateTeamContext(prev, {
        ...prev.teamState!,
        isChatDisabled: false
      })
    );
  };

  const TeamDisabledChatHandler = async (message: any) => {
    setTeam((prev) =>
      UpdateTeamContext(prev, {
        ...prev.teamState!,
        isChatDisabled: true
      })
    );
  };

  //Todo End move into layout (in game)

  let geojsonObject = {
    type: 'FeatureCollection',
    crs: {
      type: 'name',
      properties: {
        name: 'EPSG:3857'
      }
    },
    features: []
  };

  let source = new VectorSource({
    features: new GeoJSON().readFeatures(geojsonObject)
  });

  let vector = new VectorLayer({
    source: source
  });

  const getMapType = (location: string) => {
    let mapImage: string;
    let mapAsset: MapEntity | undefined = getMapAsset(location);

    if (location === 'world-map' || location === '') {
      const mapAssId = gameDoc?.rules.worldMap.mapAssId;

      mapAsset = gameDoc?.assets?.maps?.find((m) => {
        return m.id === mapAssId;
      });
      mapImage = GetResourceValue(gameDoc!, mapAsset?.imageResId!, '');
    } else {
      //check mapAssId by zone
      const zone = gameDoc?.rules.worldMap.zones.find((m) => {
        return m.zoneAssId === location;
      });
      mapAsset = gameDoc?.assets?.maps?.find((m) => {
        return m.id === zone?.mapAssId;
      });
      mapImage = GetResourceValue(gameDoc!, mapAsset?.imageResId!, '');
    }

    if (!mapAsset) return;

    //set map type state for populateGeoJsonObject
    setMapType(mapAsset.type);

    if (mapAsset?.type === 'illustration') {
      //#4857: reset Map Illustration when game has GPS and Illustration mapType
      map.setTarget(undefined);
      const img = new Image();
      img.src = mapImage;
      img.onload = () => {
        if (!isIllustrationMapLoaded) {
          const extent = [0, 0, img?.width!, img?.height!];
          const projection = new Projection({
            code: 'xkcd-image',
            units: 'pixels',
            extent: extent
          });

          mapIllustration.setView(
            new View({
              projection: projection,
              center: getCenter(extent),
              extent,
              zoom: 1
            })
          );

          const imageLayer = new ImageLayer({
            source: new Static({
              attributions: '',
              url: mapImage,
              projection: projection,
              imageExtent: extent
            })
          });

          let layers = mapIllustration.getAllLayers();
          mapIllustration.setTarget(mapElement.current);
          layers[0] = imageLayer;
          mapIllustration.setLayers(layers);

          setIsIllustrationMapLoaded(true);
        }
      };
    } else {
      //Map GPS

      //#4857: reset Map Illustration when game has GPS and Illustration mapType
      mapIllustration.setTarget(undefined);
      let layers = map.getAllLayers();
      map.setTarget(mapElement.current);

      if (layers?.length === 1) {
        map.addLayer(vector);
      }
      if (layers?.length === 2) {
        if (!isMapTypeLoaded) {
          const tileLayer = mapTypeOption(mapAsset);
          map.setLayers([tileLayer]);
          layers[0] = tileLayer;
          setIsOpenStreetMap(
            mapAsset?.type !== 'illustration' &&
              mapAsset?.type === 'openStreetMap'
              ? true
              : false
          );

          setIsMapTypeLoaded(true);
        }
        layers[1] = vector;
        map.setLayers(layers);
      }
    }
  };

  const mapTypeOption = (mapAsset: MapEntity) => {
    const tileLayer = new TileLayer({
      source:
        mapAsset.type === 'openStreetMap'
          ? new OSM()
          : new BingMaps({
              key: BingMapsKey,
              imagerySet: 'Aerial',
              maxZoom: 19
              // use maxZoom 19 to see stretched tiles instead of the BingMaps
              // "no photos at this zoom level" tiles
            })
    });

    return tileLayer;
  };

  const zoomOnPlayerLocationChanged = (location: string) => {
    if (location === 'world-map') {
      let mapAsset: MapEntity | undefined = getMapAsset(location);
      map.getView().setZoom(mapAsset?.zoomLevel ?? 17);
      centerMap(mapAsset?.latitude!, mapAsset?.longitude!, false);
    }
  };

  const getMapAsset = (location: string) => {
    if (location === 'world-map' || location === '') {
      const mapAssId = gameDoc?.rules.worldMap.mapAssId;
      return gameDoc?.assets?.maps?.find((m) => m.id === mapAssId);
    } else {
      //check mapAssId by zone
      const zone = gameDoc?.rules.worldMap.zones.find((m) => {
        return m.zoneAssId === location;
      });
      return gameDoc?.assets?.maps?.find((m) => m.id === zone?.mapAssId);
    }
  };

  const navigateToDefaultWorldmapTask = () => {
    if (player?.playerState?.location === 'world-map') {
      const tasks = getMapTasks();
      if (tasks && tasks?.length > 0) {
        centerTask(tasks[0]);
      } else {
        // if game doesn't have a task, set center of the map from mapAsset lat-long
        if (gameDocument?.gameDocument?.assets.maps) {
          let worldMap = gameDocument.gameDocument?.assets?.maps?.find(
            (m) => m.id === gameDocument.gameDocument?.rules.worldMap.mapAssId
          );

          centerMap(worldMap?.latitude!, worldMap?.longitude!, false);
        }
      }
    }
  };

  const trackAreaEventAction = async (location: string) => {
    let areas: AreaEntity[] = [];

    if (location === 'world-map') {
      areas = GetAreasWorldMap(gameDoc);
    } else {
      areas = GetAreasByZoneId(gameDoc, location);
    }

    for (const area of areas) {
      const currentCoordinate = fromLonLat([
        player.playerState?.coordinates!.longitude!,
        player.playerState?.coordinates!.latitude!
      ]);
      const prevCoordinate = fromLonLat([
        prevPlayerCoordinates?.longitude!,
        prevPlayerCoordinates?.latitude!
      ]);

      const geoJson = new GeoJSON().readFeature(area.boundary);
      if (geoJson instanceof Feature) {
        const geometry = geoJson.getGeometry();

        if (geometry) {
          const prevLocation = geometry.intersectsCoordinate(prevCoordinate);
          const currentLocation =
            geometry.intersectsCoordinate(currentCoordinate);

          if (!prevLocation && currentLocation) {
            await onEnterArea(area);
          } else if (prevLocation && !currentLocation) {
            await onExitArea(area);
          }
        }
      }
    }
  };

  //#region Update my position in map
  const showPlayerPosition = () => {
    const view = new View({
      center: [0, 0],
      zoom: 15
    });

    const geolocation = new Geolocation({
      // enableHighAccuracy must be set to true to have the heading value.
      trackingOptions: {
        enableHighAccuracy: true
      },
      projection: view.getProjection()
    });
    geolocation.setTracking(true);
    const accuracyFeature = new Feature();

    geolocation.on('change', function () {
      setAccuracy(geolocation.getAccuracy());
    });

    geolocation.on('change:accuracyGeometry', function () {
      accuracyFeature.setGeometry(geolocation.getAccuracyGeometry()!);
    });

    geolocation.on('change:position', function () {
      const coordinates = geolocation.getPosition();
      if (coordinates && player?.playerState?.code) {
        // update player longitude lattitude coordinates in localstorage
        setPlayer((prev) => {
          const response = UpdatePlayerCoordinate(prev, toLonLat(coordinates));
          return UpdatePlayerStateAsync(prev, response);
        });

        // update overlay position based on updated coordinate
        updatePlayerOverlayPosition(
          player.playerState.code,
          toLonLat(coordinates)
        );
      }
    });
  };

  const populateGeoJsonObject = () => {
    //player position
    getMapType(player?.playerState?.location!);
    source = new VectorSource({
      features: new GeoJSON().readFeatures(geojsonObject)
    });

    vector = new VectorLayer({
      source: source,
      style: function (geojsonObject) {
        // get feature type : Point(task pin) - Polygon (zone and area)
        const type = geojsonObject.getGeometry()?.getType();

        const area = gameDocument?.gameDocument?.assets?.areas?.find((x) => {
          return x.boundary?.id === geojsonObject.getId();
        });

        let findStatusArea = player.playerState?.areas?.find((t) => {
          return t.id === area?.id && t.isVisible === true;
        });

        if (type !== 'Point') {
          const zoneStyle = new Style({
            geometry: function (feature) {
              const modifyGeometry = feature.get('modifyGeometry');
              return modifyGeometry
                ? modifyGeometry.geometry
                : feature.getGeometry();
            },
            fill: new Fill({
              color: 'rgba(255, 255, 255, 0.2)'
            }),
            stroke: new Stroke({
              color: objectLineColor,
              width: 2
            }),
            image: new CircleStyle({
              radius: 7,
              fill: new Fill({
                color: objectLineColor
              })
            })
          });

          let styles: Style[] = [zoneStyle];

          const geometry = geojsonObject.getGeometry();
          const result = calculateCenter(geometry);
          const center = result.center;

          if (findStatusArea?.isVisible) {
            if (center) {
              const coordinates = result.coordinates;
              if (coordinates) {
                const minRadius = result.minRadius;
                const sqDistances = result.sqDistances;
                const rsq = minRadius * minRadius;
                const points = coordinates.filter(function (
                  coordinate: any,
                  index: any
                ) {
                  return sqDistances[index] > rsq;
                });

                styles.push(
                  new Style({
                    geometry: new MultiPoint(points),
                    image: new CircleStyle({
                      radius: 4,
                      fill: new Fill({
                        color: objectLineColor
                      })
                    })
                  })
                );
              }

              return styles;
            }
          }
        }
      }
    });

    if (mapType !== '') {
      if (mapType === 'openStreetMap' || mapType === 'satelliteMap') {
        //GPS map always have tow Layers (Tile Layer & Vector Layer), if Vector layer exist then update the vector layer.
        let layers = map.getAllLayers();
        if (layers?.length === 2) {
          layers[1] = vector;
        }

        map.setLayers(layers);
      } else {
        let layers = mapIllustration.getAllLayers();
        // If there is no vector layer, then Add the vector layer
        if (layers?.length === 1) {
          mapIllustration.addLayer(vector);
        } else if (layers?.length === 2) {
          //Layer for object Like Pin/Marker,Zone or Area object would be stored in second Layer
          layers[1] = vector;
          mapIllustration.setLayers(layers);
        }
      }
    }
  };

  const populateAreas = (location: string) => {
    if (gameDoc) {
      let areas: AreaEntity[] = [];

      if (location === 'world-map') {
        areas = GetAreasWorldMap(gameDoc);
      } else {
        areas = GetAreasByZoneId(gameDoc, location);
      }

      if (areas) {
        areas.forEach(async (ft) => {
          if (ft.boundary) {
            geojsonObject.features.push(ft.boundary as never);
          }

          let area = GetAreaById(player.playerState!, ft.id);
          if (!area) {
            let newArea: Area = {
              id: ft.id,
              isVisible: ft.isVisible
            };

            setPlayer((prev) => {
              const response = AddArea(prev.playerState!, newArea);
              return UpdatePlayerStateAsync(prev, response);
            });
          }
        });

        populateGeoJsonObject();
      }
    }
  };

  const populateAreasFromState = async () => {
    player.playerState?.areas?.forEach((area) => {
      const newArea = GetAreaByIdFromDocument(
        gameDocument?.gameDocument!,
        area.id!
      );
      if (newArea && newArea.boundary) {
        geojsonObject.features.push(newArea?.boundary as never);
      }
    });

    populateGeoJsonObject();
  };

  const populateZones = async () => {
    if (!gameDoc) return;
    const zoneIds = gameDoc.rules?.worldMap?.zones?.map(
      (zone) => zone.zoneAssId
    );

    if (zoneIds) {
      const worldZones = GetZonesByIds(gameDoc, zoneIds);

      worldZones.forEach((ft) => {
        geojsonObject.features.push(ft.boundary as never);
      });
    }
    populateGeoJsonObject();
    zoomToZone(player.playerState?.location!);
  };

  const zoomToZone = (zoneId: string) => {
    let source = vector.getSource();
    if (!zoneId) return;
    if (source) {
      let features = source.getFeatures();

      const fitOptions = { duration: 1000 };
      const zone = GetZoneById(gameDoc, zoneId);
      const zoneRules = GetZoneRulesById(gameDoc, zoneId);
      if (zone) {
        features.forEach((ft) => {
          if (ft.getId() === zone.boundary?.id) {
            let geo = ft.getGeometry() as SimpleGeometry;
            ft.unset('modifyGeometry', true);
            if (geo) {
              const mapAsset = gameDocument.gameDocument?.assets?.maps?.find(
                (m) => m.id === zoneRules?.mapAssId
              );
              if (mapAsset) {
                const tileLayer = mapTypeOption(mapAsset);
                map.setLayers([tileLayer]);
              }
              map.getView().fit(geo, fitOptions);
            }
          }
        });
      }
    }
  };

  const centerMap = (latitude: number, longitude: number, task: boolean) => {
    if (task) {
      map.getView().setCenter([latitude, longitude]);
    } else {
      map.getView().setCenter(fromLonLat([longitude, latitude]));
    }
  };

  const centerTask = (task: TaskEntity) => {
    //simplify : get first task
    const gameDocTask = task?.boundary?.geometry as any;
    centerMap(gameDocTask?.coordinates[0], gameDocTask?.coordinates[1], true);
  };

  const executeLocationEventAction = async (location: string) => {
    if (prevPlayerLocation !== undefined && prevPlayerLocation !== '') {
      await onExitLocation(prevPlayerLocation);
    }
    await onEnterLocation(location);
  };

  /**
   * Call the action onExit (map, zone)
   * @param location - world-map | zoneId
   * @construtor
   */

  const onExitLocation = async (location: string) => {
    if (location === 'world-map') {
      const worldMap = gameDocument?.gameDocument?.rules?.worldMap;
      if (worldMap) {
        const { events: { onExitMap: { steps = [] } = {} } = {} } = worldMap;
        await executeAlgorithmSteps(steps, false);
      }
    } else {
      const zone = gameDocument?.gameDocument?.assets?.zones?.find(
        (x) => x.id === location
      );
      if (zone) {
        const { events: { onExitMap: { steps = [] } = {} } = {} } = zone;
        await executeAlgorithmSteps(steps, false);
      }
    }
  };

  /**
   * Call the action onEnter (map, zone)
   * @param location - world-map | zoneId
   * @construtor
   */

  const onEnterLocation = async (location: string) => {
    if (location === 'world-map') {
      const worldMap = gameDocument?.gameDocument?.rules?.worldMap;
      if (worldMap) {
        const { events: { onEnterMap: { steps = [] } = {} } = {} } = worldMap;
        await executeAlgorithmSteps(steps, false);
      }
    } else {
      const zone = gameDocument?.gameDocument?.assets?.zones?.find(
        (x) => x.id === location
      );
      if (zone) {
        const { events: { onEnterMap: { steps = [] } = {} } = {} } = zone;

        await executeAlgorithmSteps(steps, false);
      }
    }
  };

  const onEnterArea = async (area: AreaEntity) => {
    const { events: { onEntry: { steps = [] } = {} } = {} } = area;
    await executeAlgorithmSteps(steps, false);
  };

  const onExitArea = async (area: AreaEntity) => {
    const { events: { onExit: { steps = [] } = {} } = {} } = area;
    await executeAlgorithmSteps(steps, false);
  };

  const executeAlgorithmSteps = async (
    steps: AlgorithmStep[] | AlgorithmControlStructure[],
    checkStepExecution: boolean
  ) => {
    for (const step of steps) {
      await executeAlgorithmEventHandler(step, checkStepExecution);
    }
  };

  const executeAlgorithmEventHandler = async (
    step: AlgorithmStep | AlgorithmControlStructure,
    checkStepExecution: boolean = true
  ) => {
    if (
      step.identifier === 'team' &&
      (player.playerState?.teamCode === '' ||
        player.playerState?.teamCode === undefined)
    )
      return;
    if (
      game.gameState?.status === 'Finished' ||
      game.gameState?.status === 'PostGame'
    )
      return;
    let executable: boolean = true;
    if (checkStepExecution) {
      if (IsStepExecuted(player, step.id)) {
        executable = false;
      }
    }

    if (executable) {
      setPlayer((prev) => {
        const responseStep = AddExecutedStepAsync(prev, step?.id);
        return UpdatePlayerStateAsync(prev, responseStep);
      });

      const stepControlStructure = step as AlgorithmControlStructure;
      if (stepControlStructure?.condition) {
        const controlStructureResponse =
          await executeControlStructureStepEventHandler(
            stepControlStructure,
            player,
            team
          );
        for (const step of controlStructureResponse ?? []) {
          await executeStepEventHandler(step);
        }
      } else {
        await executeStepEventHandler(step);
      }
    }
  };

  const executeStepEventHandler = async (
    step: AlgorithmStep | AlgorithmControlStructure
  ) => {
    if (step.identifier === 'player') {
      // ** Temporary solution for the race condition
      if (step.operation === 'distributeTask') {
        distributeTaskAsync(
          gameDocument?.gameCode!,
          player?.playerState?.code!,
          {
            stepId: step?.id,
            taskIds: step?.argumentAssetId as string[],
            quantity: step?.argumentQuantity
          }
        ).then((response) => {
          for (const taskId of response) {
            merge(player.playerState, ShowTaskAsync(player, taskId));
          }
          setPlayer(player);
        });
      } else {
        setPlayer((prev) => {
          const response = AlgorithmEventHandlerPlayer(
            gameDocument,
            prev,
            team,
            step
          );
          return UpdatePlayerStateAsync(cloneDeep(prev), cloneDeep(response));
        });
        toastEventHandler(player, step);
      }
    } else {
      if (player.playerState?.teamCode) {
        await AlgorithmEventHandlerAsync(gameDocument, player, team, step);
        postTeamStateAsync(
          gameDocument?.gameCode!,
          player.playerState.teamCode
        );
      }
    }
  };

  /**
   * Open task timer.
   * @param id - The boundary id when click the marker
   * @constructor
   */
  const openTaskTimer = useCallback((id: string) => {
    const task = gameDocument?.gameDocument?.assets?.tasks?.find(
      (x) => x.id === id
    );
  }, []);

  const mapIllustrationEventHandler = useCallback(
    (evt: MapBrowserEvent<any>) => {
      const taskList = document.querySelectorAll(
        '[id^="taskPlayerContainer-"]'
      );
      taskList.forEach((list) => {
        list
          .querySelector('[id^="taskPlayerList-"]')
          ?.classList.remove('d-none');
        list.querySelector('div.text-danger')?.classList.add('d-none');
        list.querySelector('span')?.classList.remove('d-none');
        list.classList.remove('expanded');
      });

      mapIllustration.forEachFeatureAtPixel(evt.pixel, function (feature) {
        const boundaryId = feature.getId();
        if (boundaryId) {
          const task = gameDocument.gameDocument?.assets?.tasks?.find(
            (x) => x.boundary?.id === boundaryId
          );
          if (task) {
            openTaskContent(task.id);
          }
        }
      });
    },
    [mapIllustration, openTaskContent, openTaskTimer]
  );

  const updatePlayerTimerStatus = (gameStatus: GameStatus): void => {
    switch (gameStatus) {
      case 'Paused':
        setPlayer((prev) => {
          const response = PlayerPauseAllTimers(prev);
          return UpdatePlayerStateAsync(prev, response);
        });
        //pause global timer
        gameClock.pause();
        break;
      case 'Running':
        if (prevGameStatus === 'Paused') {
          setPlayer((prev) => {
            const response = PlayerResumeAllTimers(prev);
            return UpdatePlayerStateAsync(prev, response);
          });
          //resume global timer
          gameClock.resume();
        }
        break;
      case 'Finished':
        setPlayer((prev) => {
          const response = PlayerStopAllTimers(prev);
          return UpdatePlayerStateAsync(prev, response);
        });
        //stop global timer
        gameClock.stop();
        break;
      default:
        break;
    }
  };

  const centerWorldMap = () => {
    if (gameDocument?.gameDocument?.assets.maps) {
      let worldMap = gameDocument.gameDocument?.assets?.maps?.find(
        (m) => m.id === gameDocument.gameDocument?.rules.worldMap.mapAssId
      );

      map.getView().setZoom(worldMap?.zoomLevel ?? 17);
      map
        .getView()
        .setCenter(
          fromLonLat([
            worldMap?.longitude ?? 152.9043310817896,
            worldMap?.latitude ?? -27.681927396540104
          ])
        );
    }
  };

  const togglePlayerPosition = () => {
    if (
      player.playerState?.location !== undefined &&
      player.playerState.location !== ''
    ) {
      let mapId: string = '';
      if (player.playerState.location === 'world-map') {
        mapId = gameDocument.gameDocument?.rules.worldMap.mapAssId!;
      } else {
        mapId = gameDocument.gameDocument?.rules.worldMap.zones.find(
          (x) => x.zoneAssId === player.playerState?.location
        )?.mapAssId!;
      }
      const mapType = gameDocument.gameDocument?.assets.maps?.find(
        (x) => x.id === mapId
      )?.type;
      if (mapType === 'openStreetMap' || mapType === 'satelliteMap')
        setShowLocation(true);
      else setShowLocation(false);
    } else {
      setShowLocation(false);
    }
  };

  const initPlayerLocation = () => {
    if (
      player.playerState?.teamCode &&
      teamHubConnection?.state === HubConnectionState.Connected
    ) {
      postTeamStateAsync(gameDocument?.gameCode!, player.playerState.teamCode);
    }

    if (
      player.playerState?.location !== undefined &&
      player.playerState.location !== ''
    )
      return;
    if (
      player.playerState?.teamCode === undefined ||
      player.playerState.teamCode === ''
    ) {
      if (playerHubConnection?.state === HubConnectionState.Connected) {
        if (
          player.playerState?.location === undefined ||
          player.playerState.location === ''
        ) {
          setPlayer((prev) => {
            const response = NavigateToWorldMap(prev);
            return UpdatePlayerStateAsync(prev, response);
          });
        }
      }
    } else {
      if (
        playerHubConnection?.state === HubConnectionState.Connected &&
        teamHubConnection?.state === HubConnectionState.Connected
      ) {
        if (
          player.playerState?.location === undefined ||
          player.playerState.location === ''
        ) {
          setPlayer((prev) => {
            const response = NavigateToWorldMap(prev);
            return UpdatePlayerStateAsync(prev, response);
          });
        }
      }
    }
  };

  /**
   *
   * @param e Street view option true/false
   */
  const onViewChange = (e: SwitchChangeEvent) => {
    const mapTypeChange = e.target.value;

    setIsOpenStreetMap(mapTypeChange); //true openStreetMap : satelliteMap
    const tileLayer = new TileLayer({
      source: mapTypeChange
        ? new OSM()
        : new BingMaps({
            key: BingMapsKey,
            imagerySet: 'Aerial',
            maxZoom: 19
            // use maxZoom 19 to see stretched tiles instead of the BingMaps
            // "no photos at this zoom level" tiles
          })
    });
    const layers = map.getAllLayers();
    if (layers.length > 0) {
      layers[0] = tileLayer;
      map.setLayers(layers);
    } else {
      map.setLayers([tileLayer]);
    }

    map.getView();
  };

  const getContentGlobal = async () => {
    updatePlayerTimerStatus('Paused');
    let response = await getFacilitatorContent(
      game?.gameCode!,
      game.contentId!
    );
    AddContentGlobalToast(response);
  };

  useEffect(() => {
    if (isGameDocLoaded || isIllustrationMapLoaded) {
      populateAreasFromState();
      populateTaskOverlay();
    }
  }, [isGameDocLoaded, isIllustrationMapLoaded, player.playerState?.tasks]);

  useEffect(() => {
    const mapEventHandler = () => {
      removeTaskDistance();
    };

    if (mapType === 'openStreetMap' || mapType === 'satelliteMap') {
      map.on('singleclick', mapEventHandler);
    } else {
      mapIllustration.on('singleclick', mapIllustrationEventHandler);
    }

    return () => {
      if (mapType === 'openStreetMap') {
        map.un('singleclick', mapEventHandler);
      } else {
        mapIllustration.un('singleclick', mapIllustrationEventHandler);
      }
    };
  }, [map, mapType, mapIllustration, mapIllustrationEventHandler]);

  useEffect(() => {
    //for GPS only, set default center of the worldmap,
    //so the first render map directly to current game position
    centerWorldMap();
  }, [map]);

  //#region Trigger add my position in map
  useEffect(() => {
    if (map && player.isLoaded && mapType !== 'illustration') {
      addMine();
    }
  }, [map, player.isLoaded, mapType, addMine]);

  //#region Trigger update my profile (avatar, name, team, and score)
  useEffect(() => {
    if (player.playerState && mapType !== 'illustration') {
      const { code, avatarImage, name, score, lastUpdated } =
        player.playerState!;
      if (
        code !== undefined &&
        avatarImage !== undefined &&
        name !== undefined &&
        score !== undefined
      ) {
        const playerStatus = getPlayerLocationStatus(lastUpdated);
        updatePlayerOverlay(
          code,
          avatarImage,
          name,
          score,
          playerStatus,
          team.teamState?.name
        );
      }
    }
  }, [player.playerState, mapType, team.teamState?.name, updatePlayerOverlay]);

  useEffect(() => {
    if (gameDocument.gameDocument) {
      populateAreas(player.playerState?.location!);
      if (!isGameDocLoaded) setIsGameDocLoaded(true);
    }
  }, [gameDocument.gameDocument, player.playerState?.location]);

  useEffect(() => {
    if (player.playerState?.location) {
      togglePlayerPosition();
      setIsIllustrationMapLoaded(false);
      setIsMapTypeLoaded(false);
      populateZones();
      executeLocationEventAction(player.playerState.location);
      getMapType(player.playerState.location);
      zoomOnPlayerLocationChanged(player.playerState.location);
      navigateToDefaultWorldmapTask();

      //reset all task overlays when changing the player's map location
      resetAllTaskOverlay();
    }
  }, [player.playerState?.location]);

  useEffect(() => {
    if (player.playerState?.coordinates) {
      showPlayerPosition();
      if (
        player.playerState.location !== undefined &&
        player.playerState.location !== '' &&
        !isEqual(prevPlayerCoordinates, player.playerState.coordinates)
      ) {
        trackAreaEventAction(player.playerState.location);
      }
    }
  }, [player.playerState?.coordinates, player.playerState?.location]);

  useEffect(() => {
    if (!gameDoc) return;
    if (isNetworkOnline()) {
      playerConnection();
      showPlayerPosition();
    } else {
      // change default of player location from '' into 'world-map'
      // base function from initPlayerLocation => need connection hub
      setPlayer((prev) => {
        const response = NavigateToWorldMap(prev);
        return UpdatePlayerStateAsync(prev, response);
      });
    }
  }, []);

  useEffect(() => {
    // Create teamconnection when the player have a teamCode

    //replace Team code has empty uuid()
    if (
      player.playerState?.teamCode === '00000000-0000-0000-0000-000000000000'
    ) {
      setPlayer((prev) =>
        UpdatePlayerContext(prev, {
          ...prev.playerState!,
          teamCode: ''
        })
      );
    }

    if (
      player.playerState?.teamCode &&
      player.playerState?.teamCode !== '' &&
      isNetworkOnline()
    ) {
      teamConnection();
    }
  }, [player.playerState?.teamCode]);

  useEffect(() => {
    if (teamHubConnection) {
      teamConnectionListener(teamHubConnection);
    }
    return () => {
      teamHubConnection?.stop();
    };
  }, [teamHubConnection]);

  useEffect(() => {
    if (playerHubConnection) {
      playerConnectionListener(playerHubConnection);
    }
    return () => {
      playerHubConnection?.stop();
    };
  }, [playerHubConnection]);

  useEffect(() => {
    if (game.gameState && game.gameState.status) {
      updatePlayerTimerStatus(game.gameState.status);
      if (game.gameState.status === 'Finished' && gameDocument.gameDocument)
        gameClock.stop();
    }
  }, [game.gameState?.status]);

  //#region Trigger add and update other players
  useEffect(() => {
    if (
      game.gameState &&
      mapType !== 'illustration' &&
      gameDoc?.settings.inGame.showOtherPlayerOrTeamOnMap
    ) {
      addAllPlayer();
    }
  }, [game.gameState, mapType, addAllPlayer]);

  useEffect(() => {
    if (game.contentId) {
      getContentGlobal();
    }
  }, [game.contentId]);

  useEffect(() => {
    if (player.playerState) {
      if (
        currentOpenedTask !== undefined &&
        currentOpenedTask !== '' &&
        (prevPlayerOpenedTask && prevPlayerOpenedTask.length > 0
          ? prevPlayerOpenedTask[prevPlayerOpenedTask.length - 1]
          : undefined) !== currentOpenedTask
      ) {
        const taskTimerId = gameDocument.gameDocument?.assets.tasks?.find(
          (x) => x.id === currentOpenedTask
        )?.timerAssId;
        if (taskTimerId !== undefined && taskTimerId !== '') {
          setPlayer((prev) => {
            const addTimer = AddTimer(prev, taskTimerId, 'stop');
            return UpdatePlayerStateAsync(prev, addTimer);
          });
        }
      } else if (
        player.playerState.location !== undefined &&
        player.playerState.location !== 'world-map'
      ) {
        const zoneTimerId =
          gameDocument.gameDocument?.rules.worldMap.zones.find(
            (x) => x.zoneAssId === player.playerState?.location
          )?.timerAssId;
        if (zoneTimerId !== undefined && zoneTimerId !== '') {
          setPlayer((prev) => {
            const addTimer = AddTimer(prev, zoneTimerId, 'stop');

            return UpdatePlayerStateAsync(prev, addTimer);
          });
        }
      } else if (player.playerState.location === 'world-map') {
        const worldmapTimerId =
          gameDocument.gameDocument?.rules.worldMap.timerAssId;
        if (worldmapTimerId !== undefined && worldmapTimerId !== '') {
          setPlayer((prev) => {
            const addTimer = AddTimer(prev, worldmapTimerId, 'stop');
            return UpdatePlayerStateAsync(prev, addTimer);
          });
        }
      }
    }
  }, [player.playerState?.location, currentOpenedTask]);

  useInterval(() => {
    isNetworkOnline() && syncPlayerState();
  }, 11000); // wait for from gameStateUpdate first 10second

  useInterval(() => {
    if (
      isNetworkOnline() &&
      teamHubConnection?.state === HubConnectionState.Connected
    ) {
      pushQueueCommandLocalstorage(player?.gameCode!);
    } else {
      teamConnection();
    }
  }, 10000);

  return (
    <>
      <div className={'canvas__map'} id={'map'} ref={mapElement as any}>
        <div
          className={
            player.playerState?.openedTask === undefined ||
            player.playerState?.openedTask.length > 0
              ? 'map'
              : 'map--wrapper'
          }
        />
        {mapType !== 'illustration' && (
          <div className={'map-view-option'}>
            <span>Street View</span>
            <Switch
              size={'small'}
              onLabel={'Street View'}
              offLabel={'Satelite View'}
              onChange={(e) => onViewChange(e)}
              checked={isOpenStreetMap}
            />
          </div>
        )}
        {showLocation && <PlayerPosition />}
      </div>
      <MapResetRotate />
      {/* WARNING: HACK development only, for test player movement using controller
          without go outside, enable when needed, and PLEASE DISABLE AGAIN WHEN PUSH */}
      {/* <PlayerController /> */}
      {mapType === 'openStreetMap' && <MoveMapPad />}
      {player.playerState?.openedTask?.map((taskId) => {
        const isAccessible = isTaskAccessible(taskId);
        return (
          <TaskContent
            id={taskId}
            key={taskId}
            mapType={mapType}
            className={'map-task-content'}
            onAlgorithmExecuted={(step) => {
              executeAlgorithmEventHandler(step, false);
            }}
            taskDistance={taskDistance}
            changeTaskIconToComplete={changeTaskIconToComplete}
            isAccesible={isAccessible}
          />
        );
      })}
      <GameStatusOverlay />
    </>
  );
}
